<template>
  <div v-if="$route.meta.hideProduct">
    <NuxtPage class="mt-2" />
  </div>
  <div class="studio-product" v-else>
    <HMStudioHeader
      v-if="!$route.meta.hideProductHeaderImage"
      class="d-md-none mb-3 overflow-hidden"
      :image="headerImage"
    />
    <div class="container my-3" v-if="product">
      <div class="row mb-3">
        <div class="col-12 d-flex flex-row align-items-center flex-wrap">
          <span class="c-charcoal">
            {{ getMediaStats }}
          </span>
        </div>
        <div
          class="col-12 mt-2 d-flex justify-content-between align-items-md-center flex-column flex-md-row"
        >
          <h1 class="flex-1">{{ product.product_name }}</h1>
          <div class="d-flex align-items-center" v-if="showRating">
            <HMRating
              class="me-1"
              :rating="product.average_rating"
              :numberOfReviews="product.number_of_reviews"
              :showRatingNumber="true"
              :iconType="studio.rating_icon_type"
            />
          </div>
        </div>
        <div
          class="col-12 d-flex flex-wrap justify-content-start justify-content-md-between align-items-center mt-2"
        >
          <div class="d-flex flex-row flex-grow-1">
            <HMInstructorsStack
              class="d-none d-md-flex"
              :instructors="product.instructors"
              size="40px"
            />
            <HMInstructorsStack
              class="d-flex d-md-none me-2"
              :instructors="product.instructors"
              size="30px"
            />
            <span
              class="ms-md-2 c-charcoal py-1"
              v-if="product.instructors.length"
            >
              {{
                $t(
                  'PRODUCTS.LABELS.INSTRUCTORS_VARIANT',
                  { cnt: product.instructors.length },
                  product.instructors.length,
                )
              }}
            </span>
          </div>
          <div class="d-flex flex-row">
            <HMProductTag
              v-for="tag in product.tags"
              :key="tag.id"
              class="mt-2 me-2"
            >
              {{ tag.name }}
            </HMProductTag>
          </div>
        </div>
      </div>
    </div>
    <StudioPurchaseToolbar
      v-if="!review"
      :product="product"
      :isBundleToolbar="review"
      :showFullActions="showFullActions"
      :isPurchasing="isPurchasing"
    />
    <div class="container" v-if="!isLoading">
      <HMStudioHeader
        v-if="!$route.meta.hideProductHeaderImage"
        class="d-none d-md-block my-3"
        :image="headerImage"
      />
      <div class="row">
        <div
          class="col-12 navigation-toolbar d-flex flex-row align-items-center justify-content-start"
        >
          <HMTabs v-model="selectedPage" class="w-100 mt-2" v-if="product">
            <HMTab :name="`${prefix}studio-product-about`">
              <NuxtLink
                class="undecorated-router-link"
                :to="
                  getRouteWithParams('studio-product-about', product.id, prefix)
                "
              >
                <span class="d-block">
                  {{ $t('PRODUCTS.LINK.ABOUT') }}
                </span>
              </NuxtLink>
            </HMTab>
            <HMTab
              :name="`${prefix}studio-product-content`"
              v-if="product.has_content"
            >
              <NuxtLink
                class="undecorated-router-link"
                :to="
                  getRouteWithParams(
                    'studio-product-content',
                    product.id,
                    prefix
                  )
                "
              >
                <span class="d-block">
                  {{ $t('PRODUCTS.LINK.CONTENT') }}
                </span>
              </NuxtLink>
            </HMTab>
            <HMTab
              :name="`${prefix}studio-product-playlists`"
              v-if="product.has_playlists"
            >
              <NuxtLink
                class="undecorated-router-link"
                :to="
                  getRouteWithParams(
                    'studio-product-playlists',
                    product.id,
                    prefix
                  )
                "
              >
                <span class="d-block d-md-none">
                  {{ $t('PRODUCTS.LINK.PLAYLISTS') }}
                </span>
                <span class="d-none d-md-block">
                  {{ $t('PRODUCTS.LINK.PLAYLISTS') }}
                </span>
              </NuxtLink>
            </HMTab>
            <HMTab
              :name="`${prefix}bundle-included-content`"
              v-if="hasBundledProducts"
            >
              <NuxtLink
                class="undecorated-router-link"
                :to="
                  getRouteWithParams(
                    'bundle-included-content',
                    product.id,
                    prefix
                  )
                "
              >
                <span>
                  {{ $t('PRODUCTS.LINK.INCLUDED_PRODUCTS') }}
                </span>
              </NuxtLink>
            </HMTab>
            <HMTab
              :name="`${prefix}studio-product-free-sample`"
              v-if="product.free_sample"
            >
              <NuxtLink
                class="undecorated-router-link"
                :to="
                  getRouteWithParams(
                    'studio-product-free-sample',
                    product.id,
                    prefix
                  )
                "
              >
                <span>
                  {{ $t('PRODUCTS.LINK.FREE_SAMPLE') }}
                </span>
              </NuxtLink>
            </HMTab>
            <HMTab
              :name="`${prefix}studio-product-lessons`"
              v-if="product.has_lessons"
            >
              <NuxtLink
                class="undecorated-router-link"
                :to="
                  getRouteWithParams(
                    'studio-product-lessons',
                    product.id,
                    prefix
                  )
                "
              >
                <span>
                  {{ $t('PRODUCTS.LINK.LESSONS') }}
                </span>
              </NuxtLink>
            </HMTab>
            <HMTab
              :name="`${prefix}studio-product-events`"
              v-if="product.events && product.events.length"
            >
              <NuxtLink
                class="undecorated-router-link"
                :to="
                  getRouteWithParams(
                    'studio-product-events',
                    product.id,
                    prefix
                  )
                "
              >
                <span v-if="!isPrivateCoaching">
                  {{ $t('PRODUCTS.LINK.EVENTS') }}
                </span>
                <span v-else>
                  {{ $t('PRODUCTS.LINK.SESSIONS') }}
                </span>
              </NuxtLink>
            </HMTab>
            <HMTab
              :name="`${prefix}studio-product-private-sessions`"
              v-if="product.has_private_sessions"
            >
              <NuxtLink
                class="undecorated-router-link"
                :to="
                  getRouteWithParams(
                    'studio-product-private-sessions',
                    product.id,
                    prefix
                  )
                "
              >
                <span>
                  {{ $t('PRODUCTS.LINK.PRIVATE_SESSIONS') }}
                </span>
              </NuxtLink>
            </HMTab>
            <HMTab
              :name="`${prefix}studio-product-reviews`"
              v-if="studio.product_reviews_enabled"
            >
              <NuxtLink
                class="undecorated-router-link"
                :to="
                  getRouteWithParams(
                    'studio-product-reviews',
                    product.id,
                    prefix
                  )
                "
              >
                <span>
                  {{ $t('PRODUCTS.LINK.REVIEWS') }}
                </span>
              </NuxtLink>
            </HMTab>
          </HMTabs>
        </div>
      </div>
      <NuxtPage class="mt-2" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useStudioStore, useUserStore, useProductStore } from '@/stores';

import { errorToMessage } from '@/utils/errors';
import _ from 'lodash-es';
import RefreshData from '@/mixins/refreshData';
import { metaTitles } from '@/utils/meta-utils';
import StudioPurchaseToolbar from '@/components/shop/StudioPurchaseToolbar.vue';
import {
  getRouteName,
  getRouteWithParams,
} from '@/utils/route-converter';
import SignContractModal from '@/components/contracts/SignContractModal.vue';

export default {
  name: 'StudioProduct',
  components: {
    StudioPurchaseToolbar,
  },
  async setup() {
    const studioStore = useStudioStore();
    const userStore = useUserStore();
    const productStore = useProductStore();

    return {
      studioStore,
      userStore,
      productStore,
    }
  },
  mixins: [RefreshData],
  props: {
    review: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(useProductStore, {
      product: 'product',
    }),
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
      currency: 'currency'
    }),
    ...mapState(useUserStore, {
      isLoggedIn: 'isLoggedIn',
    }),
    metaTitle() {
      return this.product?.product_name
        ? `${this.product?.product_name} — ${this.studio?.studio_name}`
        : null;
    },
    isPrivateCoaching() {
      return this.product?.product_type === 'private_coaching';
    },
    studioURL() {
      return this.$studioURL;
    },
    productId() {
      return this.$route.params.product_id;
    },
    headerImage() {
      return this.product?.cover_file;
    },
    getMediaStats() {
      if (this.product?.product_type === 'punch_card') {
        return this.$t(
          'PRODUCTS.LABELS.STATS_NUMBER_OF_REDEMPTIONS_VARIANT',
          { cnt: this.product.number_of_punches },
          this.product.number_of_punches,
        );
      }
      if (!this.product.display_product_stats) {
        return '';
      }
      let result = '';
      const { audio, video, pdf, other } = this.product.media_counts;
      const docs = pdf + other;
      if (video > 0) {
        result = this.$t(
          'PRODUCTS.LABELS.STATS_NUMBER_OF_VIDEOS_VARIANT',
          { cnt: video },
          video,
        );
      }
      if (audio > 0) {
        const txt = this.$t(
          'PRODUCTS.LABELS.STATS_NUMBER_OF_AUDIOS_VARIANT',
          { cnt: audio },
          audio,
        );
        if (result.length > 0) result = `${result} · `;
        result = `${result}${txt}`;
      }
      if (docs > 0) {
        const txt = this.$t(
          'PRODUCTS.LABELS.STATS_NUMBER_OF_DOC_VARIANT',
          { cnt: docs },
          docs,
        );
        if (result.length > 0) result = `${result} · `;
        result = `${result}${txt}`;
      }
      return result;
    },
    hasBundledProducts() {
      return this.product?.bundled_products?.length;
    },
    isPurchased() {
      return this.product?.purchased;
    },
    isBundle() {
      return this.product?.product_type === 'bundle';
    },
    showRating() {
      return (
        this.studio?.product_reviews_enabled && this.product?.number_of_reviews
      );
    },
  },
  data() {
    return {
      isLoading: false,
      selectedPage: this.$route.name,
      isPurchasing: false,
      showFullActions: false,
      pendingContracts: [],
    };
  },
  methods: {
    getRouteWithParams(route, productId, prefix) {
      return getRouteWithParams(route, productId, prefix);
    },
    getRouteName(route, prefix) {
      return getRouteName(route, prefix);
    },
    onScroll(event) {
      if(process.client) {
        this.showFullActions = 290 < window.scrollY;
      }
    },
    async checkPendingContracts() {
      this.pendingContracts = await useProductStore().getPendingContracts({
          studioURL: this.studioURL,
          productId: this.productId,
      });
      if (this.pendingContracts.length) {
        this.pendingContracts.forEach(contract => {
          this.$modal.show(
            SignContractModal,
            {
              signedContract: contract,
            },
            {
              clickToClose: false,
              escToClose: false,
              contentClass: ['full-width-modal'],
            }
          );
        });
      }
    },
    async loadData() {
      this.isLoading = true;
      try {
        await this.productStore.getProductDetail({
          studioURL: this.studioURL,
          productId: this.productId,
        });
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Failed to load product',
          text: errorToMessage(error.response, `Request failed: ${error}`),
        });
      } finally {
        this.isLoading = false;
      }
      try {
        await this.productStore.trackProductView({
          studioURL: this.studioURL,
          productId: this.productId,
        });
      } catch (e) {
        console.log('Error tracking product view');
      }
    },
    // checkForRedirect() {
    //   // check for required redirect
    //   if (
    //     !['studio-bundled-product', 'studio-product'].includes(this.$route.name)
    //   )
    //     return;

    //   if (this.isPurchased) {
    //     if (this.product.has_private_sessions) {
    //       this.$router.replace({
    //         name: this.getRouteName(
    //           'studio-product-private-sessions',
    //           this.prefix
    //         ),
    //       });
    //     } else if (this.product.has_content) {
    //       this.$router.replace({
    //         name: this.getRouteName('studio-product-content', this.prefix),
    //       });
    //     } else if (this.product.has_lessons) {
    //       this.$router.replace({
    //         name: this.getRouteName('studio-product-lessons', this.prefix),
    //       });
    //     } else if (this.product.has_playlists) {
    //       this.$router.replace({
    //         name: this.getRouteName('studio-product-playlists', this.prefix),
    //       });
    //     } else if (this.product.events?.length) {
    //       this.$router.replace({
    //         name: this.getRouteName('studio-product-events', this.prefix),
    //       });
    //     } else if (this.product.bundled_products?.length) {
    //       this.$router.replace({
    //         name: this.getRouteName('bundle-included-content', this.prefix),
    //       });
    //     } else {
    //       this.$router.replace({
    //         name: this.getRouteName('studio-product-about', this.prefix),
    //       });
    //     }
    //   } else {
    //     this.$router.replace({
    //       name: this.getRouteName('studio-product-about', this.prefix),
    //     });
    //   }
    // },
    async initPage() {
      this.selectedPage = this.$route.name;
      this.onScroll();
      await this.checkPendingContracts();
      // this.checkForRedirect();
    },
  },
  async mounted() {
    document.addEventListener('scroll', this.onScroll);
    await this.initPage();
  },
  beforeUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  },
  watch: {
    $route(to, from) {
      this.selectedPage =
        to.name || this.getRouteName('studio-product-about', this.prefix);
      console.log('watch $route', this.selectedPage);
    },
    '$route.params.product_id': {
      handler: async function (product_id) {
        await this.initPage();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.studio-product {
  @include media-breakpoint-up(md) {
    margin-top: $shop-topbar-lg-height;
  }
}

.studio-product-image {
  margin-top: 1rem;
  display: none;
  @include media-breakpoint-up(md) {
    display: flex;
  }
}
</style>
