<template>
  <div
    v-if="product"
    class="purchase-toolbar mt-3"
    :class="{
      'container-fluid': showFullActions,
      container: !showFullActions,
    }"
  >
    <div class="row purchase-toolbar-line">
      <div class="col-12">
        <hr class="mt-0 mb-1" />
      </div>
    </div>
    <div class="row purchase-toolbar-content">
      <div class="col purchase-toolbar-title">
        <div
          v-if="showFullActions"
          class="purchase-toolbar-tags flex-row flex-wrap"
        >
          <HMProductTag
            v-for="tag in product.tags"
            :key="tag.id"
            class="me-2"
            :selected="tag.selected"
          >
            {{ tag.name }}
          </HMProductTag>
        </div>
        <h4 class="m-0">
          <span v-if="isBundleToolbar" class="me-2">{{ $t('PRODUCTS.TITLE.PART_OF') }} </span
          >{{ product.product_name }}
        </h4>
      </div>
      <div
        class="col col-md-auto purchase-toolbar-actions"
        :class="{
          'w-100': !showFullActions,
        }"
      >
        <span v-if="canWishPrice" class="wish-price-available-tooltip">
          <i
            class="fa fa-handshake-o me-2"
            v-tooltip="{
              content: $t('PRODUCTS.TEXT.PAY_WHAT_YOU_WISH_AVAILABLE'),
              delay: 100,
              trigger: 'click hover',
              placement: 'bottom',
              container: 'body',
            }"
          ></i>
        </span>
        <div v-if="canPurchase">
          <NuxtLink
            class="undecorated-router-link day-pass"
            v-if="isFreeProduct"
            :to="{
              name: 'studio-product-checkout',
              params: { product_id: product.id },
            }"
          >
            <HMPriceButton
              class="bg-brand-color c-white"
              :loading="isPurchasing"
            >
              <template v-slot:text>
                <span class="buy-min-width">{{
                  $t('PRODUCTS.BUTTON.REGISTER')
                }}</span>
              </template>
              <template v-slot:price>
                {{ getPriceText }}
              </template>
            </HMPriceButton>
          </NuxtLink>
          <NuxtLink
            class="undecorated-router-link day-pass"
            v-if="isDayPassEnabled && product.day_pass_price"
            :to="{
              name: 'studio-product-checkout',
              params: { product_id: product.id },
              query: { daypass: true },
            }"
          >
            <HMPriceButton class="bg-charcoal c-white" :loading="isPurchasing">
              <template v-slot:text>
                {{ $t('PRODUCTS.BUTTON.BUY_DAY_PASS') }}
              </template>
              <template v-slot:price>
                {{ $formatCurrency(product.day_pass_price, currency) }}
              </template>
            </HMPriceButton>
          </NuxtLink>
          <NuxtLink
            class="undecorated-router-link fixed-price"
            v-if="isFixedPriceProduct"
            :to="{
              name: 'studio-product-checkout',
              params: { product_id: product.id },
            }"
          >
            <HMPriceButton
              class="bg-brand-color c-white"
              :loading="isPurchasing"
            >
              <template v-slot:text>
                <span v-if="isPurchased">{{
                  $t('PRODUCTS.BUTTON.BUY_AGAIN')
                }}</span>
                <span v-else>{{ $t('PRODUCTS.BUTTON.BUY') }}</span>
              </template>
              <template v-slot:price>
                {{ getPriceText }}
              </template>
            </HMPriceButton>
          </NuxtLink>
          <NuxtLink
            class="undecorated-router-link subscription"
            v-if="isRecurringProduct"
            :to="{
              name: 'studio-product-checkout',
              params: { product_id: product.id },
            }"
          >
            <HMButton
              v-if="product.trial"
              class="bg-brand-color c-white w-100"
              :loading="isPurchasing"
            >
              {{ $t('PRODUCTS.BUTTON.BUY_START_TRIAL') }}
            </HMButton>
            <HMPriceButton
              v-else
              class="bg-brand-color c-white"
              :loading="isPurchasing"
            >
              <template v-slot:text>
                {{ $t('PRODUCTS.BUTTON.BUY_SUBSCRIPTION') }}
              </template>
              <template v-slot:price>
                {{ getPriceText }}
              </template>
            </HMPriceButton>
          </NuxtLink>
        </div>
        <span v-if="canWishPrice" class="wish-price-available">
          <i class="fa fa-handshake-o me-2"></i>
          <span>
            {{ $t('PRODUCTS.TEXT.PAY_WHAT_YOU_WISH_AVAILABLE') }}
          </span>
        </span>
        <div
          v-else-if="!isEnrollmentAvailable"
          class="d-flex justify-content-between w-100 align-items-center"
        >
          <span>
            {{ $t('PRODUCTS.TEXT.ENROLLMENT_IS_CURRENTLY_CLOSED') }}
          </span>
          <div v-if="!isOwned && !isPurchased">
            <HMButton
              class="ms-3"
              @click="openAddToWaitlistModel"
              color="brand"
              v-if="product.waitlist_enabled"
            >
              {{ $t('PRODUCTS.BUTTON.JOIN_THE_WAITLIST') }}
            </HMButton>
          </div>
        </div>
        <div v-else-if="isOwned" class="ms-2">
          {{ $t('PRODUCTS.TEXT.YOU_OWN_THIS_PRODUCT') }}
        </div>
        <div v-else-if="isPurchased" class="ms-2">
          <span v-if="isFreeProduct">
            {{ $t('PRODUCTS.TEXT.YOU_ARE_REGISTERED') }}
          </span>
          <span v-else>
            <span v-if="isExpired">
              {{
                $t('PRODUCTS.TEXT.ACCESS_EXPIRED', {
                  date: $formatDate(product.expiration_date, 'date'),
                })
              }}.
            </span>
            <span
              v-if="
                product.expiration_date &&
                product.is_subscription &&
                !product.is_subscription_canceled
              "
              class="d-inline-flex align-items-center"
            >
              <span v-if="product.is_paused">
                {{ $t('PRODUCTS.TEXT.ACCESS_IS_PAUSED') }}.
              </span>
              <!--              <HMTag v-if="product.is_paused" status="paused">-->
              <!--                {{ $t('PRODUCTS.TEXT.ACCESS_IS_PAUSED') }}.-->
              <!--              </HMTag>-->
              <span v-else>
                {{
                  $t('PRODUCTS.TEXT.ACCESS_RENEWS_ON', {
                    date: $formatDate(product.expiration_date, 'date'),
                  })
                }}.
              </span>
            </span>
            <span v-else-if="product.expiration_date">
              {{
                $t('PRODUCTS.TEXT.ACCESS_UNTIL', {
                  date: $formatDate(product.expiration_date, 'date'),
                })
              }}.
            </span>
            <span v-else>
              {{ $t('PRODUCTS.TEXT.YOU_PURCHASED_THIS_PRODUCT') }}
            </span>
            <span class="ms-1" v-if="!canPurchaseAgain">
              {{ $t('PRODUCTS.TEXT.MULTIPLE_PURCHASES_ARE_DISABLED') }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="row purchase-toolbar-line">
      <div class="col-12">
        <hr class="mt-1 mb-0" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import _ from 'lodash-es';
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores';
import AddToWaitlistModal from '@/components/shop/AddToWaitlistModal.vue';

export default {
  name: 'StudioPurchaseToolbar',
  props: {
    product: {
      type: Object,
    },
    showFullActions: {
      type: Boolean,
      default: false,
    },
    isPurchasing: {
      type: Boolean,
      default: false,
    },
    isBundleToolbar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useStudioStore, {
      currency: 'currency',
    }),
    isDayPassEnabled() {
      return this.product.day_pass_enabled;
    },
    isFreeProduct() {
      return this.product.pricing_type === 'free';
    },
    isFixedPriceProduct() {
      return this.product.pricing_type === 'fixed';
    },
    isRecurringProduct() {
      return this.product.pricing_type === 'recurring';
    },
    isRecurringMultiple() {
      return this.product.recurring_type === 'multiple';
    },
    isPurchased() {
      return this.product.purchased;
    },
    isOwned() {
      return this.product.owned;
    },
    isEnrollmentAvailable() {
      return this.product.enrollment_available;
    },
    canPurchaseAgain() {
      return this.product.can_purchase_again;
    },
    canPurchase() {
      if (!this.isEnrollmentAvailable) return false;
      if (this.isOwned) return false;
      return !this.isPurchased || this.canPurchaseAgain;
    },
    canWishPrice() {
      return (
        this.product.wish_price_enabled &&
        this.canPurchase &&
        (this.isFixedPriceProduct || this.isRecurringProduct)
      );
    },
    getPriceText() {
      if (this.product.pricing_type === 'free') {
        return this.$t('PRODUCTS.LABELS.FREE');
      }
      if (this.product.pricing_type === 'fixed') {
        return this.$formatCurrency(this.product.price || 0, this.currency);
      }
      if (this.product.pricing_type === 'recurring') {
        if (this.product.recurring_type !== 'multiple') {
          const period = this.$t(
            `PRODUCTS.LABELS.RECURRING_${this.product.recurring_type.toUpperCase()}`
          );
          return `${this.$formatCurrency(
            this.product.price || 0,
            this.currency
          )}/${period}`;
        } else {
          const prefix = this.$t('PRODUCTS.LABELS.FROM');
          const arr = _.values(
            _.transform(
              this.product.recurring_options,
              (result, value, key) => {
                result[key] = { ...value, period: key };
              }
            )
          );
          const onlyAvailable = arr.filter(opt => opt.available);
          const cheapestOption = _.minBy(onlyAvailable, option =>
            parseFloat(option.price)
          );
          const period = this.$t(
            `PRODUCTS.LABELS.RECURRING_${cheapestOption.period.toUpperCase()}`
          );
          return `${prefix} ${this.$formatCurrency(
            cheapestOption.price || 0,
            this.currency
          )}/${period}`;
        }
      }
      return 'ERROR';
    },
    productId() {
      return this.$route.params.product_id;
    },
    isExpired() {
      if (!this.product?.expiration_date) return false;
      const today = new Date();
      const expirationDate = new Date(this.product?.expiration_date);
      return this.product?.expiration_date && expirationDate < today;
    },
  },
  methods: {
    openAddToWaitlistModel() {
      this.$modal.show(AddToWaitlistModal, {
        studioURL: this.$studioURL,
        productId: this.productId,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
.buy-min-width {
  @include media-breakpoint-up(md) {
    min-width: 150px;
  }
}

.purchase-toolbar {
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  align-items: stretch;
  z-index: $zindex-fixed + 1;
  background: white;
  min-height: 66px;
  @include media-breakpoint-up(md) {
    position: sticky;
    bottom: initial;
    top: 59px;
    &.container-fluid {
      box-shadow: 0px 2px 4px rgba(50, 50, 50, 0.2);
      .purchase-toolbar-content {
        padding: 0 2.5rem;
        align-items: center;
      }
      .col-12 {
        padding: 0;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    max-width: 100vw;
    &.container-fluid,
    &.container {
      box-shadow: 0px -1px 5px rgba(50, 50, 50, 0.2);
    }
  }
  .undecorated-router-link {
    display: block;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
  .undecorated-router-link + .undecorated-router-link {
    margin-top: 1rem;
    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-left: 1rem;
    }
  }
}

.purchase-toolbar-actions {
  display: flex;
  flex-direction: column-reverse;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.wish-price-available-tooltip {
  display: none;
  margin-right: 2rem;
}

.wish-price-available {
  color: $charcoal;
  margin: 0 0 0.75rem 0;
  display: inline-block;
  font-size: 0.625rem;
  @include media-breakpoint-up(md) {
    margin: 0 0 0 1rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1rem;

    color: $dark-gray;
  }
}

.purchase-toolbar.container-fluid {
  .wish-price-available {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  .wish-price-available-tooltip {
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}

.purchase-toolbar-line {
  .col-12 {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }
}

.purchase-toolbar-title {
  display: none;
  align-items: center;
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.container {
  .purchase-toolbar-title {
    display: none;
  }
}

.purchase-toolbar-tags {
  display: none;
  align-items: center;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}
</style>
