<template>
  <button class="price-button" v-bind="$attrs">
    <div class="price-button-text">
      <slot name="text"></slot>
    </div>
    <div class="price-button-price">
      <slot name="price"></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'HMPriceButton',
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.price-button {
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0;
  border: none;
  height: 42px;
  width: 100%;
  &:hover {
    filter: brightness(95%);
  }
}

.price-button-text {
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  flex: 1;
  text-align: left;
  @include media-breakpoint-up(md) {
    text-align: center;
  }
}

.price-button-price {
  display: flex;
  align-items: center;
  background-color: $lightest-gray;
  border: 1px solid $light-gray;
  color: $charcoal;
  border-left-color: transparent;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0 20px;
  height: 100%;
}
</style>
