<template>
  <Form
    tag="form"
    @submit="addToWaitlist"
    v-slot="{ meta }"
  >
    <HMModalHeader
      :title="$t('PRODUCTS.BUTTON.JOIN_THE_WAITLIST')"
      @close="$attrs._close()"
    />
    <HMTextInput
      type="email"
      name="email"
      :label="$t('GENERAL.LABEL.EMAIL')"
      :placeholder="$t('PRODUCTS.LABELS.YOUR_EMAIL')"
      v-model="waitlistEmail"
    />
    <HMModalFooter
      :submitButton="$t('PRODUCTS.BUTTON.JOIN_THE_WAITLIST')"
      :loading="inProgress"
      :disabled="!meta.valid"
    />
  </Form>
</template>

<script>
import { Form } from 'vee-validate';
import api from '@/api/products';
import { errorToMessage } from '@/utils/errors';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores';

export default {
  components: {
    Form,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    studioURL: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      waitlistEmail: '',
      formError: null,
      inProgress: false,
    };
  },
  computed: {
    ...mapState(useUserStore, {
      user: 'user',
    }),
  },
  methods: {
    async addToWaitlist() {
      this.inProgress = true;
      try {
        await api.addToWaitlist(
          this.studioURL,
          this.productId,
          this.waitlistEmail
        );
        this.waitlistEmail = '';
        this.$notify({
          group: 'app',
          type: 'success',
          title: this.$t(
            'PRODUCTS.ERROR.YOU_WERE_SUCCESSFULLY_ADDED_TO_THE_WAITLIST'
          ),
        });
        this.$attrs._close();
      } catch (error) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: this.$t('PRODUCTS.ERROR.YOUR_SUBSCRIPTION_TO_WAITLIST_FAILED'),
          text: errorToMessage(error.response, `Request failed: ${error}`),
        });
      } finally {
        this.inProgress = false;
      }
    },
  },
  mounted() {
    const email = this.user?.email;
    if (email) {
      this.waitlistEmail = email;
    }
  },
};
</script>
